<script>
	import IconButton from '$lib/IconButton.svelte';
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import { onMount } from 'svelte';
	import CloseIcon from 'svelte-material-icons/Close.svelte';

	export let title = '';
	export let isLoading = false;
	export let onClose;
	export let status = '';

	let show = false;
	onMount(() => {
		setTimeout(() => {
			show = true;
		}, 100);
	});
</script>

<div class="modal-container" class:error={status === 'error'}>
	<div class="modal" class:show>
		{#if title || onClose}
			<div class="modal-header">
				<span>{title}</span>
				{#if onClose}
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				{/if}
			</div>
		{/if}

		<div class="modal-body">
			<slot />
		</div>

		{#if $$slots.footer}
			<div class="modal-footer">
				<slot name="footer" />
			</div>
		{/if}

		{#if isLoading}
			<div class="loading">
				<SavingIcon />
			</div>
		{/if}
	</div>
	<div class="modal-overlay" />
</div>

<style lang="scss">
	.modal-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100000;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal {
		position: relative;
		background-color: white;
		box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
		border-radius: 8px;
		width: 512px;
		z-index: 100001;
		opacity: 0;
		transform: scale(98%);
		transition: all 0.3s ease;

		&.show {
			opacity: 1;
			transform: scale(100%);
		}
	}

	.modal-header {
		padding: 25px 25px 10px;
		font-size: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.modal-footer {
		padding: 15px 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #f1f1f4;
	}

	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 100000;
	}

	.loading {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal.error {
		.modal-header {
			background-color: var(--color-error);
			color: #fff;
		}
	}
</style>
